import { Button, Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled } from 'components/NewForms/FormStyled';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  AssetConditionTag,
  AssetDTO,
  AssetStatusTag,
  AssetType,
} from 'types/Asset';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { convertUTCtoLocalTime, getTimeDifference } from 'utils/utilFunctions';
import { createAsset, updateAssetById } from 'api/assetService';
import { useAssetsData } from 'ui-v2/hooks/useAssetsData';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import moment, { Moment } from 'moment';
import { useAssetCategoriesData } from 'ui-v2/hooks/useAssetCategoriesData';
import { fetchAssetSubCategories } from 'redux/assetCategories/actions';
import { AssetSubCategory } from 'types/AssetCategories';
import { OptionType } from 'types/OptionTypes';
import CategorySubcategoryForm from 'pages/Assets/CategorySubcategoryForm';
import CardItem from 'ui-v2/components/Card';
import {
  fetchAssetCategoriesOptions,
  fetchDepartmentsOptions,
  fetchTenantLocationsOptions,
} from '../SelectWithLoad/utils';

interface IProps {
  selectedAsset?: AssetType | null;
}

export default function AssetForm({ selectedAsset }: IProps) {
  const [form] = Form.useForm();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { singleAsset } = useAssetsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [selectedAssetStatus, setSelectedAssetStatus] = useState<
    AssetStatusTag | undefined
  >(singleAsset?.data?.status);
  const [warrantyStartDate, setWarrantyStartDate] = useState(
    selectedAsset?.warrantyStartDate
      ? moment(selectedAsset?.warrantyStartDate)
      : null
  );
  const [warrantyEndDate, setWarrantyEndDate] = useState(
    selectedAsset?.warrantyEndDate
      ? moment(selectedAsset?.warrantyEndDate)
      : null
  );
  const [warrantyDuration, setWarrantyDuration] = useState('');
  const [selectedAssetCategoryId, setSelectedAssetCategoryId] = useState<any>();
  const { subCategories: subCategoriesState } = useAssetCategoriesData();
  const [subCategoryOptions, setSubCategoryOptions] = useState<
    Array<OptionType>
  >([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getWarrantyDuration = getTimeDifference(
      warrantyStartDate,
      warrantyEndDate,
      t
    );
    if (warrantyStartDate && warrantyEndDate && getWarrantyDuration) {
      setWarrantyDuration(getWarrantyDuration);
    }

    if (singleAsset?.data?.status) {
      setSelectedAssetStatus(singleAsset?.data?.status);
    }
  }, [warrantyStartDate, warrantyEndDate, t, singleAsset?.data?.status]);

  const handleWarrantyStartDateChange = (date: Moment | null): void => {
    setWarrantyStartDate(date);
    if (date) form.setFieldsValue({ warrantyStartDate: date });
  };
  const handleWarrantyEndDateChange = (date: Moment | null): void => {
    setWarrantyEndDate(date);
    if (date) form.setFieldsValue({ warrantyEndDate: date });
  };

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  const onFieldsChange = () => {
    setSelectedAssetStatus(form.getFieldValue('status'));
  };

  const onCategorySelect = () => {
    setSelectedAssetCategoryId(form.getFieldValue('categoryId')?.value);
  };

  useEffect(() => {
    if (selectedAsset?.assetSubCategory?.assetCategory.id) {
      setSelectedAssetCategoryId(
        selectedAsset.assetSubCategory.assetCategory.id
      );
    }
  }, [selectedAsset?.assetSubCategory]);

  useEffect(() => {
    if (selectedAssetCategoryId) {
      dispatch(fetchAssetSubCategories(selectedAssetCategoryId));
    }
    form.setFieldsValue({ assetSubCategoryId: null });
  }, [selectedAssetCategoryId]);

  useEffect(() => {
    setSubCategoryOptions(
      subCategoriesState?.data.map((assetSubCategory: AssetSubCategory) => ({
        id: assetSubCategory.id,
        label: assetSubCategory.subCategoryName,
        value: assetSubCategory.id,
      }))
    );
  }, [subCategoriesState?.data]);

  const setCategory = (categoryId: string, name: string) => {
    form.setFieldsValue({ categoryId: { value: categoryId, label: name } });
    setSelectedAssetCategoryId(categoryId);
  };

  const setSubCategory = (subCategoryId: string, name: string) => {
    form.setFieldsValue({
      assetSubCategoryId: { value: subCategoryId, label: name },
    });
  };

  const toggleCollapsed = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] =
    useMemo(() => {
      const formConfig: any[][] = [
        [
          {
            col: 11,
            offset: 0,
            name: 'name',
            label: t('name'),
            type: 'input',
            defaultValue: form.getFieldValue('name') || selectedAsset?.name,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          {
            col: 11,
            offset: 2,
            name: 'type',
            label: t('type'),
            type: 'input',
            defaultValue: form.getFieldValue('type') || selectedAsset?.type,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 11,
            offset: 0,
            name: 'serialNumber',
            label: t('serialNumber'),
            type: 'input',
            defaultValue:
              form.getFieldValue('serialNumber') || selectedAsset?.serialNumber,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          {
            col: 11,
            offset: 2,
            name: 'purchaseDate',
            label: t('purchaseDate'),
            type: 'datepicker',
            defaultValue:
              form.getFieldValue('purchaseDate') ||
              convertUTCtoLocalTime(selectedAsset?.purchaseDate),
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 11,
            offset: 0,
            name: 'warrantyStartDate',
            label: t('Warranty Start Date'),
            type: 'datepicker',
            defaultValue:
              form.getFieldValue('warrantyStartDate') ||
              convertUTCtoLocalTime(selectedAsset?.warrantyStartDate),
            onChange: handleWarrantyStartDateChange,
          },
          {
            col: 11,
            offset: 2,
            name: 'warrantyEndDate',
            label: t('Warranty End Date'),
            type: 'datepicker',
            disabled: !warrantyStartDate,
            defaultValue:
              form.getFieldValue('warrantyEndDate') ||
              convertUTCtoLocalTime(selectedAsset?.warrantyEndDate),
            onChange: handleWarrantyEndDateChange,
            rules: [
              {
                required: !!warrantyStartDate,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 11,
            offset: 0,
            name: 'purchasePrice',
            label: t('purchasePrice'),
            type: 'selectPrefix',
            defaultValue:
              form.getFieldValue('purchasePrice') ||
              selectedAsset?.purchasePrice,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
            inputProps: {
              type: 'number',
              rows: 1,
              min: 0,
            },
            loading: currencyLoading,
            prefix: {
              name: 'currencyId',
              selectOptions: currencyOptions,
              placeholder: '',
              defaultValue:
                selectedAsset?.currency?.id || currencyOptions[1]?.id,
              rules: [
                {
                  required: true,
                  message: t('fieldRequired'),
                },
              ],
            },
          },
          {
            col: 11,
            offset: 2,
            name: 'condition',
            label: t('condition'),
            type: 'select',
            selectOptions: Object.values(AssetConditionTag).map(
              (item: string) => ({
                id: item,
                value: item,
                label: t(item),
              })
            ),
            defaultValue:
              form.getFieldValue('condition') || selectedAsset?.condition,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'location',
            label: t('assetLocation'),
            type: InputTypes.SELECT_LOAD,
            fetchOptions: fetchTenantLocationsOptions,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: selectedAssetStatus === AssetStatusTag.SHARED ? 24 : 11,
            offset: 0,
            name: 'status',
            label: t('assetStatus'),
            type: 'select',
            selectOptions: Object.values(AssetStatusTag).map(
              (item: string) => ({
                id: item,
                value: item,
                label: t(item),
              })
            ),
            defaultValue: form.getFieldValue('status') || selectedAssetStatus,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          selectedAssetStatus === AssetStatusTag.SHARED
            ? {
                col: 24,
                offset: 0,
                name: 'department',
                label: t('department'),
                type: InputTypes.SELECT_LOAD,
                defaultValue:
                  form.getFieldValue('department') ||
                  selectedAsset?.department?.id,
                onSelect: () => form.setFieldsValue({ employee: null }),
                fetchOptions: fetchDepartmentsOptions,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              }
            : {
                col: 11,
                offset: 2,
                name: 'employee',
                label: t('employee'),
                type: InputTypes.SELECT_LOAD,
                onSelect: () => form.setFieldsValue({ department: null }),
                defaultValue:
                  form.getFieldValue('employee') || selectedAsset?.employee?.id,
                fetchOptions: getEmployeeSkimSelectWithLoadOptions,
                rules: [
                  {
                    required: true,
                    message: t('fieldRequired'),
                  },
                ],
              },
        ],
        [
          {
            col: 11,
            offset: 0,
            name: 'categoryId',
            label: t('assetCategory'),
            type: InputTypes.SELECT_LOAD,
            fetchOptions: fetchAssetCategoriesOptions,
            onSelect: onCategorySelect,
            defaultValue:
              form.getFieldValue('categoryId') ||
              selectedAsset?.assetSubCategory?.assetCategory?.categoryName,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          {
            col: 11,
            offset: 2,
            name: 'assetSubCategoryId',
            label: t('assetSubCategory'),
            type: InputTypes.SELECT,
            disabled: !selectedAssetCategoryId,
            defaultValue: !form.getFieldValue('categoryId')?.value
              ? selectedAsset?.assetSubCategory?.id
              : form.getFieldValue('assetSubCategoryId'),
            selectOptions: subCategoryOptions,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
        ],
        [
          {
            col: 11,
            offset: 0,
            name: 'add',
            type: InputTypes.BUTTON,
            label: (
              <Button
                style={{ border: 'none', color: '#096dd9' }}
                onClick={toggleCollapsed}
              >
                {t('addAssetCategory')}
              </Button>
            ),
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'notes',
            label: t('notes'),
            type: 'textarea',
            defaultValue: form.getFieldValue('notes') || selectedAsset?.notes,
            textAreaProps: { rows: 5 },
          },
        ],
        [
          {
            col: 24,
            offset: 0,
            name: 'description',
            label: t('description'),
            type: 'textarea',
            defaultValue:
              form.getFieldValue('description') || selectedAsset?.description,
            textAreaProps: { rows: 5 },
          },
        ],
      ];
      return formConfig;
    }, [
      currencyOptions,
      selectedAsset,
      selectedAssetStatus,
      selectedAssetCategoryId,
      subCategoryOptions,
      subCategoriesState,
      warrantyStartDate,
    ]);

  const onSubmit = async () => {
    if (loading) return;

    setLoading(true);
    const formValues = form.getFieldsValue(true);
    let valuesToSend: AssetDTO = {
      name: formValues.name,
      notes: formValues.notes,
      description: formValues.description,
      serialNumber: formValues.serialNumber,
      purchaseDate: formValues.purchaseDate,
      purchasePrice: Number(formValues.purchasePrice),
      condition: formValues.condition,
      type: formValues.type,
      currencyId: formValues.currencyId,
      location: String(formValues.location),
      isAssigned: !!selectedAsset?.isAssigned,
      status:
        typeof formValues?.status === 'string'
          ? formValues?.status
          : formValues?.status?.value,
      warrantyStartDate: formValues.warrantyStartDate,
      warrantyEndDate: formValues.warrantyEndDate,
      assetSubCategory:
        formValues.assetSubCategoryId?.value ?? formValues.assetSubCategoryId,
    };

    if (formValues?.department) {
      valuesToSend = {
        ...valuesToSend,
        departmentId:
          typeof formValues?.department === 'string'
            ? formValues?.department
            : formValues?.department?.value,
        isAssigned: true,
        employeeId: undefined,
      };
    }
    if (formValues?.employee) {
      valuesToSend = {
        ...valuesToSend,
        employeeId:
          typeof formValues?.employee === 'string'
            ? formValues?.employee
            : formValues?.employee?.value,
        isAssigned: true,
        departmentId: undefined,
      };
    }

    if (selectedAsset) {
      try {
        const res = await updateAssetById(selectedAsset?.id, valuesToSend);
        if (res.status === 200) {
          navigate('/assets');
          toast.success(t('Successfully updated asset'));
        }
      } catch (err) {
        toast.error(t("Asset couldn't be updated"));
      }
    } else {
      try {
        const res = await createAsset(valuesToSend);
        if (res.status === 200) {
          navigate('/assets');
          toast.success(t('successfullyCreatedAsset'));
        }
      } catch (err) {
        toast.error(t("Asset couldn't be created"));
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('add-save-asset') === 'true') {
      searchParams.delete('add-save-asset');
      form.submit();
      setSearchParams(searchParams);
    }
    if (searchParams.get('edit-save-asset') === 'true') {
      searchParams.delete('edit-save-asset');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <CardItem title="">
      <>
        <Row>
          <Col>
            {`${t('Warranty Duration')}: ${warrantyDuration || t('Not set')}`}
          </Col>
        </Row>
        <GenericForm
          formConfiguration={AddWorkExperienceFormConfiguration}
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
          loading={loading}
        >
          <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
            {modalVisible && (
              <CategorySubcategoryForm
                open={modalVisible}
                closeModal={() => setModalVisible(false)}
                setCategory={setCategory}
                setSubCategory={setSubCategory}
              />
            )}
          </FormItemStyled>
        </GenericForm>
      </>
    </CardItem>
  );
}
