import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SidebarItem,
  SidebarItemGroup,
  getAdminHrGroupedSidebarItems,
  getAdminHrSidebarItems,
  getEditorGroupedSidebarItems,
  getEditorSidebarItems,
  getEmployeeGroupedSidebarItems,
  getEmployeeSidebarItems,
  getHeadOfDepartmentSidebarItems,
  getGuestGroupedSidebarItems,
  getGuestSidebarItems,
  getGuestTrackingHoursGroupedSidebarItems,
  getGuestTrackingHoursSidebarItems,
  getSuperAdminGroupedSidebarItems,
  getSuperAdminSidebarItems,
  getPayrollManagerSidebarItems,
} from 'layouts/BasicLayout/AppSidebar/utils';
import { AUTH_ROLES } from 'types/Auth';
import { TFunction } from 'i18next';
import { isHeadDepartment } from 'utils/utilFunctions';
import useHeader from './useHeader';

export const useSidebarItems = () => {
  const [items, setItems] = useState<SidebarItem[]>([]);
  const navigate = useNavigate();
  const [groupedItems, setGroupedItems] = useState<SidebarItemGroup[]>([]);

  const location = useLocation();
  const { authUserRole, authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const { entity } = useHeader();
  const [isHeadOfDepartment, setIsHeadOfDepartment] = useState<boolean>(false);

  useEffect(() => {
    const employeeId = authUser?.employee?.id as string;
    if (employeeId) {
      isHeadDepartment(employeeId).then((res: boolean) => {
        setIsHeadOfDepartment(res);
      });
    }
  }, [authUser?.employee?.id]);

  const getAuthUserSidebarItems = useCallback(async (): Promise<
    Array<SidebarItem>
  > => {
    if (isHeadOfDepartment && authUserRole === AUTH_ROLES.EMPLOYEE) {
      return getHeadOfDepartmentSidebarItems();
    }
    switch (authUserRole) {
      case AUTH_ROLES.AMDIN:
        return getAdminHrSidebarItems();
      case AUTH_ROLES.HR:
        return getAdminHrSidebarItems();
      case AUTH_ROLES.EDITOR:
        return getEditorSidebarItems();
      case AUTH_ROLES.EMPLOYEE:
        return getEmployeeSidebarItems();
      case AUTH_ROLES.PAYROLL_MANAGER:
        return getPayrollManagerSidebarItems();
      case AUTH_ROLES.GUEST:
        return getGuestSidebarItems();
      case AUTH_ROLES.GUEST_TRACKING_HOURS:
        return getGuestTrackingHoursSidebarItems();
      case AUTH_ROLES.SUPER_ADMIN:
        return getSuperAdminSidebarItems();
      default:
        return [];
    }
  }, [authUserRole, isHeadOfDepartment]);

  const getAuthUserGroupedSidebarItems =
    useCallback((): Array<SidebarItemGroup> => {
      switch (authUserRole) {
        case AUTH_ROLES.AMDIN:
          return getAdminHrGroupedSidebarItems();
        case AUTH_ROLES.HR:
          return getAdminHrGroupedSidebarItems();
        case AUTH_ROLES.EDITOR:
          return getEditorGroupedSidebarItems();
        case AUTH_ROLES.EMPLOYEE:
        case AUTH_ROLES.PAYROLL_MANAGER:
          return getEmployeeGroupedSidebarItems();
        case AUTH_ROLES.GUEST:
          return getGuestGroupedSidebarItems();
        case AUTH_ROLES.GUEST_TRACKING_HOURS:
          return getGuestTrackingHoursGroupedSidebarItems();
        case AUTH_ROLES.SUPER_ADMIN:
          return getSuperAdminGroupedSidebarItems();
        default:
          return [];
      }
    }, [authUserRole]);

  const isActivePath = useCallback(
    ({
      items,
      grouped = false,
    }: {
      items: SidebarItem[];
      grouped?: boolean;
    }) => {
      if (grouped) {
        return !!items?.find((item) => item.path.includes(entity));
      }
      return !!items?.find((item) => location.pathname === item.path);
    },
    [authUserRole, location.pathname, entity]
  );

  useEffect(() => {
    if (!entity?.length) {
      navigate('/login');
    }
  }, [entity]);

  useEffect(() => {
    const fetchData = async () => {
      if (authUserRole) {
        const items = await getAuthUserSidebarItems();
        setItems(items);
        setGroupedItems(getAuthUserGroupedSidebarItems());
      }
    };

    fetchData();
  }, [authUserRole, getAuthUserSidebarItems, getAuthUserGroupedSidebarItems]);

  const onSearch = async ({
    searchedValue,
    t,
  }: {
    searchedValue: string;
    t: TFunction;
  }) => {
    if (!searchedValue?.length) {
      setItems(await getAuthUserSidebarItems());
      setGroupedItems(getAuthUserGroupedSidebarItems());
      return;
    }
    const foundGroupedItems =
      getAuthUserGroupedSidebarItems()
        ?.map((gItem) => gItem.items.map((el) => el))
        ?.flat()
        ?.filter((item) =>
          t(item.name).toLowerCase().startsWith(searchedValue.toLowerCase())
        ) || [];

    const foundGroups = getAuthUserGroupedSidebarItems()?.filter((item) =>
      t(item.title).toLowerCase().startsWith(searchedValue.toLowerCase())
    );

    const foundItems = (await getAuthUserSidebarItems())?.filter((item) =>
      t(item.name).toLowerCase().startsWith(searchedValue.toLowerCase())
    );

    setItems([...foundGroupedItems, ...foundItems]);
    setGroupedItems(foundGroups);
  };

  return {
    items,
    groupedItems,
    isActivePath,
    onSearch,
  };
};
