import { Col, Popconfirm } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { EmergencyContactDto } from 'types/EmergencyContact';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { useTranslation } from 'react-i18next';
import {
  EmergencyContactContainer,
  Name,
  Relationship,
  PhoneNumber,
  AvatarStyle,
  StyledCol,
  StyledRow,
  StyledText,
} from './EmergencyContactItemStyled';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface IProps {
  item: EmergencyContactDto;
  onDelete?: () => void;
  isLast: boolean;
  onItemClick: (id: string) => void;
}

export function EmergencyContactItem({
  item,
  onDelete,
  isLast,
  onItemClick,
}: IProps) {
  const handleItemClick = () => {
    onItemClick(item.id);
  };
  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  const { t } = useTranslation();

  return (
    <EmergencyContactContainer isLast={isLast}>
      <StyledRow onClick={handleItemClick} isLast={isLast}>
        <Col span={3}>
          <AvatarStyle size={40} shape="circle">
            {item.fullName
              .split(' ')
              .map((name) => name.charAt(0))
              .join('')
              .toUpperCase()}
          </AvatarStyle>
        </Col>
        <StyledCol span={6}>
          <Name>{item.fullName}</Name>
          <Relationship>{`${item.relationship}`}</Relationship>
        </StyledCol>
        <Col span={13}>
          <PhoneNumber href={`tel:${item.phoneNumber}`}>
            {`Tel: +${item.phoneNumber}`}
          </PhoneNumber>
          <StyledText>{`${item.email}`}</StyledText>
          <StyledText>{`${item.country}, ${item.city}, ${item.address}`}</StyledText>
        </Col>
        <Col span={2}>
          <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
            <Popconfirm
              placement="topLeft"
              title={t('areYouSureYouWanttoDelete')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={(e) => handleDeleteButtonClick(e)}
            >
              <IconButton onClick={(e) => e.stopPropagation()}>
                <DeleteIcon />
              </IconButton>
            </Popconfirm>
          </RoleBasedButton>
        </Col>
      </StyledRow>
    </EmergencyContactContainer>
  );
}
