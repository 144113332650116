import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EdititingIcon } from 'Icons/EditIcon';
import AddFeedbackModal from 'ui-v2/components/Employees/EmployeeGoals/AddFeedbackModal';
import { t } from 'i18next';
import { CheckSquareOutlined } from '@ant-design/icons';
import UpdateGoalModal from 'ui-v2/components/Employees/EmployeeGoals/UpdateGoalModal';
import { DeleteIcon } from 'Icons/DeleteIcon';
import { deleteGoal } from 'api/goalService';
import { toast } from 'react-toastify';
import { AuthUserState } from 'redux/authUser/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { fetchGoals } from 'redux/goal/action';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

interface GoalActionsProps {
  id: string;
  row: {
    createdBy: string;
  };
}

const GoalActions: React.FC<GoalActionsProps> = ({ id, row }) => {
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const dispatch = useDispatch();
  const { authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const isAdminOrHr = authUser?.roles.some(
    (role) => role.name === AUTH_ROLES.AMDIN || role.name === AUTH_ROLES.HR
  );

  const onEditClick = () => {
    setModal(true);
  };

  const onUpdateClick = () => {
    setUpdateModal(true);
  };

  const onDeleteClick = async (id: string) => {
    try {
      await deleteGoal(id);
      const params = {
        pageOptions: { page: 1, take: 10 },
      };
      dispatch(fetchGoals(params));
      toast.success('Goal deleted successfully!');
    } catch (error) {
      toast.warning('Could not delete the goal!');
    }
  };
  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('editGoal')}>
              <IconButton onClick={onUpdateClick}>
                <CheckSquareOutlined style={{ fontSize: '19px' }} />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t('leaveFeedback')}>
              <IconButton onClick={onEditClick}>
                <EdititingIcon />
              </IconButton>
            </Tooltip>
          </Col>
          {authUser?.employee?.id === row.createdBy || isAdminOrHr ? (
            <Col>
              <Popconfirm
                placement="topLeft"
                title={`${t('deleteGoal')}?`}
                onConfirm={() => onDeleteClick(id)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Popconfirm>
            </Col>
          ) : null}
        </Row>
      </Col>
      {modal && (
        <AddFeedbackModal
          open={modal}
          closeModal={() => setModal(false)}
          goalId={id}
        />
      )}

      {updateModal && (
        <UpdateGoalModal
          open={updateModal}
          closeModal={() => setUpdateModal(false)}
          goalId={id}
        />
      )}
    </Row>
  );
};

export default GoalActions;
