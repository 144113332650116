import { FormInstance } from 'antd';
import moment from 'moment';

import { AssigmentType } from 'types/Assigment';
import { AUTH_ROLES } from 'types/Auth';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { ProjectType } from 'types/Project';
import { TenantConfigDTO } from 'types/TenantConfig';
import {
  convertUTCtoLocalTime,
  getTime,
  getUserDateFormat,
} from 'utils/utilFunctions';
import { getUTCConvertedDateTime } from 'ui-v2/routes/tracking/utils';
import { UserDataType } from 'redux/authUser/types';
import {
  TrackedHourCreateDTO,
  TrackedHourDTO,
  TrackedHourJobLocation,
  TrackedHourType,
} from 'types/tracking';
import { isNil } from 'lodash';
import i18next from '../../../i18n';
import { SelectLoadPayload } from '../SelectWithLoad/SelectWithLoad';

export const getThProjectFormItemLabel = (
  userRole: string | undefined,
  hasAssignments: boolean,
  hasOwnProjects: boolean,
  name: string | undefined,
  authUserFullName?: string
): string => {
  const fallbackMsg = i18next.t('therearenoprojectsassigned');
  let label = 'Project List';
  switch (userRole) {
    case AUTH_ROLES.EMPLOYEE:
    case AUTH_ROLES.PAYROLL_MANAGER:
      if (!hasOwnProjects) {
        if (authUserFullName && !name) {
          label = `${fallbackMsg} to you ${authUserFullName}`;
        } else {
          label = fallbackMsg;
        }
      }
      break;
    case AUTH_ROLES.AMDIN:
      if (!hasAssignments) {
        label = name ? `${fallbackMsg} to ${name}` : fallbackMsg;
      }
      break;
    case AUTH_ROLES.HR:
      if (!hasAssignments) {
        label = name ? `${fallbackMsg} to ${name}` : fallbackMsg;
      }
      break;
    case AUTH_ROLES.GUEST_TRACKING_HOURS:
      if (!hasAssignments) {
        label = fallbackMsg;
      }
      break;
    default:
      break;
  }
  return label;
};

export const getThProjectFormItemDefaultSelectOption = (
  trackingHour?: TrackedHourDTO
): OptionType[] => {
  if (trackingHour?.assignment) {
    return [
      {
        id: trackingHour.assignment.id,
        value: trackingHour.assignment.id,
        label: trackingHour.assignment.project.name,
      },
    ];
  }
  return [];
};

export const getThProjectFormItemSelectOptions = (
  employeeProjects: ProjectType[],
  assignments?: AssigmentType[]
): OptionType[] => {
  if (employeeProjects.length) {
    return [
      ...(employeeProjects?.map((p: ProjectType) => [
        ...(p?.assignments?.map((a: AssigmentType) => ({
          id: a?.id,
          label: p?.name,
          value: a?.id,
        })) ?? []),
      ]) ?? []),
    ].flat();
  }
  const assignmentProjectOptions = assignments
    ?.filter((el) => !isNil(el?.project))
    .map((item) => ({
      id: item?.id,
      value: item?.id,
      label: item?.project?.name,
    }));

  return assignmentProjectOptions || [];
};

export const getThFormConfigurations = ({
  form,
  onEmployeeSelect,
  trackedHour,
  tenantConfig,
  loggedInUserRole,
  assignments,
  employeeProject,
  onHourTypeSelect,
  onJobLocationSelect,
  defaultEmployeeOption,
  defaultProjectOption,
  authUser,
  onProjectSelect,
  getEmployeeSkimSelectWithLoadOptions,
}: {
  form: FormInstance;
  onEmployeeSelect: (value: any) => void;
  trackedHour?: TrackedHourDTO;
  tenantConfig?: TenantConfigDTO | null;
  loggedInUserRole?: string;
  assignments?: AssigmentType[];
  employeeProject?: ProjectType[];
  onHourTypeSelect?: (id: string) => void;
  onJobLocationSelect?: (id: string) => void;
  defaultEmployeeOption?: OptionType;
  defaultProjectOption?: OptionType;
  authUser?: UserDataType | null;
  onProjectSelect?: (value: any) => void;
  getEmployeeSkimSelectWithLoadOptions: (
    str: string
  ) => Promise<SelectLoadPayload>;
}) => {
  const projetListLabel = getThProjectFormItemLabel(
    loggedInUserRole,
    !!assignments?.length,
    !!employeeProject?.length,
    form.getFieldsValue(true)?.employeeId?.label,
    `${authUser?.firstName} ${authUser?.lastName}`
  );

  const projectSelectOptions = getThProjectFormItemSelectOptions(
    employeeProject ?? [],
    assignments
  );

  let defaultEmployeeValue: OptionType[] = [];
  if (!defaultEmployeeOption && trackedHour?.id) {
    defaultEmployeeValue = [
      {
        id: trackedHour?.employee?.id,
        value: trackedHour?.employee?.id,
        label: `${trackedHour?.employee?.firstName} ${trackedHour?.employee?.lastName}`,
      },
    ];
  } else if (defaultEmployeeOption) {
    defaultEmployeeValue = [defaultEmployeeOption];
  }

  const userDateFormat = getUserDateFormat();

  const startTimeDefaultValue = trackedHour?.startTime
    ? trackedHour?.startTime
    : tenantConfig?.workingHourStart ?? form.getFieldValue('startTime');
  const endTimeDefaultValue =
    form.getFieldValue('endTime') || trackedHour?.endTime;

  const formConfiguration: FormConfigurationType[][] = [
    !trackedHour && loggedInUserRole
      ? [
          {
            col: 11,
            offset: 0,
            name: 'hourType',
            label: i18next.t('hourType'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourType)?.map(
              (hourType) => ({
                id: hourType,
                value: hourType,
                label: hourType,
              })
            ),
            onSelect: onHourTypeSelect,
          },
          {
            col: 11,
            offset: 0,
            name: 'isRemote',
            label: i18next.t('jobLocation'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourJobLocation)?.map(
              (jobLocation) => ({
                id: jobLocation,
                value: jobLocation,
                label: jobLocation,
              })
            ),
            onSelect: onJobLocationSelect,
          },
        ]
      : [
          {
            col: 11,
            offset: 0,
            name: 'isRemote',
            label: i18next.t('jobLocation'),
            type: InputTypes.CHECKBOX_GROUP,
            checkboxOptions: Object.values(TrackedHourJobLocation)?.map(
              (jobLocation) => ({
                id: jobLocation,
                value: jobLocation,
                label: jobLocation,
              })
            ),
            checkedOptions: [
              trackedHour?.isRemote
                ? {
                    id: TrackedHourJobLocation.REMOTE,
                    value: TrackedHourJobLocation.REMOTE,
                    label: TrackedHourJobLocation.REMOTE,
                  }
                : {
                    id: TrackedHourJobLocation.OFFICE,
                    value: TrackedHourJobLocation.OFFICE,
                    label: TrackedHourJobLocation.OFFICE,
                  },
            ],
            onSelect: onJobLocationSelect,
          },
        ],
    loggedInUserRole === AUTH_ROLES.AMDIN || loggedInUserRole === AUTH_ROLES.HR
      ? [
          {
            col: 24,
            offset: 0,
            name: 'employeeId',
            label: i18next.t('employeeList'),
            type: InputTypes.SELECT_LOAD,
            fetchOptions: getEmployeeSkimSelectWithLoadOptions,
            onSelect: onEmployeeSelect,
            defaultValue: defaultEmployeeValue,
            rules: [
              {
                required: true,
                message: i18next.t('Employee is required'),
              },
            ],
          },
        ]
      : [],
    [
      {
        col: 24,
        offset: 0,
        name: 'projectId',
        label: projetListLabel,
        type: InputTypes.SELECT,
        disabled: !projectSelectOptions?.length,
        selectOptions: projectSelectOptions,
        defaultValue: defaultProjectOption?.id,
        onSelect: onProjectSelect,
      },
      {
        col: 6,
        offset: 0,
        name: 'startTime',
        label: i18next.t('startTime'),
        type: InputTypes.TIMEPICKER,
        defaultValue: getTime(startTimeDefaultValue),
        rules: [
          {
            required: true,
            message: i18next.t('Start time is required!'),
          },
        ],
      },
      {
        col: 6,
        offset: 3,
        name: 'endTime',
        label: i18next.t('endTime'),
        type: InputTypes.TIMEPICKER,
        defaultValue: getTime(endTimeDefaultValue),
        rules: [
          {
            required: true,
            message: i18next.t('End time is required!'),
          },
        ],
      },
      {
        col: 6,
        offset: 3,
        name: 'date',
        label: i18next.t('date'),
        type: InputTypes.DATEPICKER,
        defaultValue: convertUTCtoLocalTime(trackedHour?.date),
        dateProps: {
          format: userDateFormat,
          showTime: false,
          disabledDate: (current: moment.Moment) => current > moment(),
        },
        rules: [
          {
            required: true,
            message: i18next.t('Date is required!'),
          },
        ],
      },
    ],
    [
      {
        col: 24,
        offset: 0,
        name: 'description',
        label: i18next.t('description'),
        type: InputTypes.TEXTAREA,
        defaultValue: trackedHour?.description,
        rules: [
          {
            required: true,
            message: i18next.t('requiredDescription'),
          },
        ],
      },
    ],
  ];
  return formConfiguration;
};
export const getThPayload = (
  formValues: any,
  employeeId?: string,
  withProject?: boolean
): TrackedHourCreateDTO => {
  let startTime: any = moment(formValues?.startTime);
  let endTime: any = moment(formValues?.endTime);

  if (endTime.isSameOrBefore(startTime)) {
    endTime = getUTCConvertedDateTime({
      date: moment(formValues?.date).add(1, 'day'),
      time: endTime,
    });
  } else {
    endTime = getUTCConvertedDateTime({
      date: formValues?.date,
      time: endTime,
    });
  }

  startTime = getUTCConvertedDateTime({
    date: formValues?.date,
    time: startTime,
  });

  const projectIdValue =
    typeof formValues?.projectId === 'string'
      ? formValues?.projectId
      : formValues?.projectId?.value;
  const payload: TrackedHourCreateDTO = {
    date: getUTCConvertedDateTime({
      date: formValues?.date,
      time: formValues?.startTime,
    }),
    startTime,
    endTime,
    hourType: TrackedHourType.REGULAR_HOURS,
    description: formValues?.description,
    employeeId: employeeId || formValues?.employeeId?.value,
  };
  return withProject && projectIdValue
    ? { ...payload, assignmentId: projectIdValue }
    : payload;
};
