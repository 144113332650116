import React, { useEffect, useMemo, useState } from 'react';
import { EmployeeType } from 'types/Employee';
import { WorkPositionListModelType } from 'types/WorkPosition';
import { InputTypes } from 'types/FormTypes';
import {
  convertBase64,
  convertDateToUTC,
  convertUTCtoLocalTime,
  isDuplicateEmailError,
  isOfUrlValid,
} from 'utils/utilFunctions';
import moment from 'moment';
import GenericForm from 'components/NewForms/Form';
import { toast } from 'react-toastify';
import { updateEmployee } from 'api/employeeService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/lib/upload';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { useDispatch } from 'react-redux';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';
import { fetchTenantLocationsOptions } from 'components/NewForms/SelectWithLoad/utils';
import { Gender } from 'types/Candidates';
import { phoneNumberValidator, prefixSelector } from '../utils';

interface IProps {
  employee: EmployeeType | undefined;
  workPosition: WorkPositionListModelType | undefined;
  currencyId: string | undefined;
}
const Preview = () => <FileOutlinedIcon />;

function EditEmployeeForm({ employee, workPosition, currencyId }: IProps) {
  const [form] = useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { filteredWorkPositions } = useWorkPositionsSkimData();
  const navigate = useNavigate();
  const [fileToUpload, setFileToUpload] = useState<{
    name?: string;
    content?: string;
  }>({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { optionsType: employmentTypes } = useGetSelectOptions({
    type: 'employement-type',
  });

  const { optionsType: currencyOptions, loading: currencyLoading } =
    useGetSelectOptions({
      type: 'currency',
    });

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    setFileToUpload({ name: file.name, content: base64 });
    return false;
  }

  const FormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'email',
          label: t('email'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          defaultValue: employee?.email,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'workEmail',
          label: t('workEmail'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'email',
          },
          defaultValue: employee?.workEmail,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: employee?.phoneNumber,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
            {
              validator: phoneNumberValidator,
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: employee?.phoneNumberPrefix || prefixSelector[0].id,
            placeholder: '',
          },
        },
        {
          col: 11,
          offset: 2,
          name: 'birthDate',
          label: t('birthday'),
          type: 'datepicker',
          defaultValue: employee?.birthDate
            ? convertUTCtoLocalTime(employee?.birthDate)
            : '',
          birthday: true,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 8,
          offset: 0,
          name: 'gender',
          label: t('gender'),
          type: 'select',
          selectOptions: Object.values(Gender).map((item: Gender) => ({
            id: item,
            value: item,
            label: item,
          })),
          defaultValue: employee?.gender,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'ssn',
          label: t('ssn'),
          type: InputTypes.INPUT,
          defaultValue: employee?.ssn,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'linkedIn',
          label: 'LinkedIn',
          type: InputTypes.INPUT,
          defaultValue: employee?.linkedIn,
        },
      ],
      [
        {
          col: 8,
          offset: 0,
          name: 'hireDate',
          label: t('hireDate'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(employee?.hireDate),
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'onBoardingStartDate',
          label: t('onboardStart'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(employee?.onBoardingStartDate),
          rules: [
            {
              required: true,
              message: t('On Boarding Start Date is required!'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'onBoardingEndDate',
          label: t('onboardEnd'),
          type: InputTypes.DATEPICKER,
          defaultValue: convertUTCtoLocalTime(employee?.onBoardingEndDate),
          dateProps: {
            format: 'DD/MM/YYYY',
            showTime: false,
            disabledDate: (current: moment.Moment) =>
              moment(current).isSameOrBefore(
                form.getFieldValue('onBoardingStartDate')
              ),
          },
          rules: [
            {
              required: true,
              message: t('On boarding end date is required!'),
            },
          ],
        },
      ],
      [
        {
          col: 8,
          offset: 0,
          name: 'employmentTypeId',
          label: t('employmentType'),
          type: InputTypes.SELECT,
          selectOptions: employmentTypes,
          defaultValue:
            employee &&
            employmentTypes.find((e) => e.label === employee?.onBoardingStatus)
              ?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'maxWorkHoursPerMonth',
          label: t('maxworkhours/Month'),
          defaultValue: employee?.maxWorkHoursPerMonth,
          type: InputTypes.INPUT,
          inputProps: {
            type: 'number',
          },
          rules: [
            {
              required: false,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 6,
          offset: 2,
          name: 'employeeIdentifier',
          label: 'Employee Code',
          defaultValue: employee?.employeeIdentifier,
          type: InputTypes.INPUT,
          rules: [
            {
              required: false,
              message: 'This field is required',
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'salary',
          label: t('grossSalary'),
          type: 'selectPrefix',
          defaultValue: employee?.salary,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          inputProps: {
            type: 'number',
            rows: 1,
            min: 0,
          },
          prefix: {
            name: 'currencyId',
            selectOptions: currencyOptions,
            placeholder: '',
            defaultValue: currencyId || currencyOptions[1]?.id,
            rules: [
              {
                required: true,
                message: t('fieldRequired'),
              },
            ],
          },
          loading: currencyLoading,
        },
        {
          col: 11,
          offset: 2,
          name: 'salaryNeto',
          label: t('netSalary'),
          type: InputTypes.INPUT,
          defaultValue: employee?.salaryNeto,
          inputProps: {
            type: 'number',
          },
          rules: [
            {
              required: false,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: filteredWorkPositions,
          defaultValue: workPosition?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'tenantLocationId',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          defaultValue: `${employee?.tenantLocation?.country},${employee?.tenantLocation?.city}`,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'resume',
          type: InputTypes.UPLOAD,
          label: t('uploadCV'),
          defaultValue: employee?.resume,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
          uploadProps: {
            accept: ".pdf,'data:application/pdf;base64,'",
            beforeUpload: (file: RcFile) => uploadAction(file),
            maxCount: 1,
            listType: 'picture',
            iconRender: Preview,
            defaultFileList:
              employee?.resume &&
              ([
                {
                  uid: '1',
                  name: employee?.resume,
                },
              ] as any),
          },
        },
      ],
    ],
    [employmentTypes, filteredWorkPositions, currencyOptions, employee]
  );

  async function onFormFinish(values: any) {
    if (values.salary < 0) {
      toast.warning(t('pleaseEnterAValidSalary!'));
      isLoading(false);
      return;
    }
    let formValues = values;
    formValues = {
      ...formValues,
      onBoardingStatus: employmentTypes.find(
        (e) => e.id === formValues.employmentTypeId
      )?.label,
      onBoardingStartDate: convertDateToUTC(formValues.onBoardingStartDate),
      onBoardingEndDate: convertDateToUTC(formValues.onBoardingEndDate),
      tenantLocationId: formValues?.tenantLocationId?.key,
      gender: formValues.gender.charAt(0),
      salary: Number(values.salary),
      salaryNeto: Number(values.salaryNeto),
      maxWorkHoursPerMonth: Number(values.maxWorkHoursPerMonth),
      resume: {
        name: fileToUpload?.name || employee?.resume,
        content: fileToUpload?.content?.split(',').pop() || employee?.resume,
      },
      workPositionId: formValues?.workPositionId,
      phoneNumberPrefix: formValues?.phoneNumberPrefix || prefixSelector[0].id,
    };

    delete formValues.candidate;
    delete formValues.isFromScratch;

    if (
      !formValues.linkedIn ||
      String(formValues.linkedIn).replace(/\s/g, '').length === 0
    ) {
      delete formValues.linkedIn;
    } else if (!isOfUrlValid(formValues.linkedIn)) {
      toast.warning(t('LinkedIn URL is not valid!'));
      return;
    }
    isLoading(true);
    if (employee?.id) {
      const payload = {
        ...formValues,
        hireDate: convertDateToUTC(formValues?.hireDate),
        workPositionId: formValues?.workPositionId,
      };
      await updateEmployee(employee.id, payload)
        .then((response) => {
          if (response.status === 200) {
            if (employee?.id) {
              dispatch(fetchEmployeesDetail(employee.id));
            }
            toast.success(t('Employee updated successfully!'));
            navigate(`/employees/employee-profile?id=${employee.id}`);
          }
        })
        .catch((error) => {
          if (isDuplicateEmailError(error)) {
            toast.warning(t('This work e-mail is already in use'));
            return;
          }
          toast.error(t('somethingWentWrong'));
        })
        .finally(() => isLoading(false));
    }
  }

  useEffect(() => {
    if (searchParams.get('add-employee-save') === 'true') {
      searchParams.delete('add-employee-save');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-employee-save') === 'true') {
      searchParams.delete('edit-employee-save');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <GenericForm
      formConfiguration={FormConfiguration}
      onFinish={onFormFinish}
      form={form}
      loading={loading}
    />
  );
}

export default EditEmployeeForm;
