import React from 'react';
import { RulesType } from 'types/FormTypes';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {
  FormItemStyled,
  StyledInput,
  StyledPasswordInput,
} from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  rules?: RulesType[];
  inputProps?: {
    type: 'number' | 'email' | 'text' | 'password' | 'decimal';
    min?: number;
    max?: number;
    maxLength?: number;
  };
  validateStatus?: 'error' | 'validating';
  disabled?: boolean;
  help?: string;
  hideLabel?: boolean | undefined;
  hint?: React.ReactNode | string;
}

const InputComponent: React.FC<Props> = ({
  placeholder,
  name,
  rules,
  inputProps,
  validateStatus,
  disabled,
  help,
  hideLabel = false,
  hint,
}) => {
  const blockInvalidChar = (e: { key: string; preventDefault: () => any }) => {
    if (
      inputProps?.type === 'number' &&
      !/^[0-9]$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'Enter' &&
      e.key !== '.'
    ) {
      e.preventDefault();
    }
  };

  return (
    <FormItemStyled
      name={name}
      label={!hideLabel ? placeholder : null}
      rules={rules}
      validateStatus={validateStatus}
      help={help}
      {...(hint && { tooltip: hint })}
    >
      {inputProps?.type === 'password' ? (
        <StyledPasswordInput
          {...inputProps}
          iconRender={(visible) => {
            if (visible) {
              return <EyeTwoTone />;
            }
            return <EyeInvisibleOutlined />;
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <StyledInput
          {...inputProps}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={blockInvalidChar}
        />
      )}
    </FormItemStyled>
  );
};

InputComponent.defaultProps = {
  rules: [],
  inputProps: {
    type: 'text',
  },
  disabled: false,
};

export default InputComponent;
