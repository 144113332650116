import moment from 'moment';

import { CandidateType } from 'types/Candidates';
import { EmployeeAssignmnetsType, EmployeeType } from 'types/Employee';
import { convertUTCtoLocalTime, formatDate } from 'utils/utilFunctions';
import { ProjectType } from 'types/Project';
import { WorkPositionSingleType } from 'types/WorkPosition';
import { AssetType } from 'types/Asset';
import { StageName } from 'types/Stages';
import React from 'react';
import {
  AppstoreAddOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  ScheduleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { CheckIcon } from 'Icons/CheckIcon';
import { CloseIcon } from 'Icons/CloseIcon';
import { CandidateStatus } from 'types/CandidateStatus';

import { TrainingType } from 'types/Training';
import { SingleCertificationDTO } from 'types/Certification';
import { DepartmentSingleType } from 'types/Department';
import { TenantDataType } from 'types/Tenant';
import { VendorQuestionnaireCategory } from 'types/VendorQuestionnaire';
import i18next from '../../../i18n';
import { HireDateStyle } from './HeaderStyles';

const SpaceIcon = styled.span`
  margin-right: 5px;
`;
export const getPageTitle = ({
  path,
  candidate,
  employee,
  project,
  workPosition,
  asset,
  user,
  training,
  certification,
  department,
  categoryName,
  tenant,
  vendorQuestionnaireCategory,
}: {
  path: string;
  candidate: CandidateType | null;
  employee: EmployeeType | null;
  project: ProjectType | null;
  workPosition: WorkPositionSingleType | null;
  asset: AssetType | null;
  user: any | null;
  training: TrainingType | null;
  certification: SingleCertificationDTO | null;
  department: DepartmentSingleType | null;
  categoryName: string | null;
  tenant?: TenantDataType;
  vendorQuestionnaireCategory?: VendorQuestionnaireCategory | null;
}) => {
  const candidateText = `${candidate?.firstName} ${
    candidate?.lastName
  }, ${moment().diff(convertUTCtoLocalTime(candidate?.birthDate), 'years')}`;

  const employeeText = `${employee?.firstName} ${
    employee?.lastName
  }, ${moment().diff(convertUTCtoLocalTime(employee?.birthDate), 'years')}`;

  const projectText = `${project?.name}`;
  switch (path) {
    case 'skills':
      return i18next.t('skillCategories');
    case 'category-details':
      return `${categoryName}`;
    case 'edit-user':
      return i18next.t('Edit User');
    case 'my-profile':
      return `${user?.firstName} ${user?.lastName}`;
    case 'change-password':
      return `${user?.firstName} ${user?.lastName}`;
    case 'edit-user-profile':
      return `${user?.firstName} ${user?.lastName}`;
    case 'profile':
      return candidate?.firstName && candidate?.lastName && candidate.birthDate
        ? candidateText
        : '';
    case 'add-candidate':
      return i18next.t('addCandidate');
    case 'employee-dashboard':
      return i18next.t('Dashboard');
    case 'vendors':
      return i18next.t('vendors');
    case 'feed':
      return i18next.t('Feed');
    case 'edit-profile':
      return candidate?.firstName && candidate?.lastName && candidate.birthDate
        ? candidateText
        : i18next.t('editCandidate');
    case 'hire-candidate':
      return candidate?.firstName && candidate?.lastName && candidate.birthDate
        ? candidateText
        : i18next.t('hireCandidate');
    case 'users':
      return i18next.t('Users');
    case 'work-positions':
      return i18next.t('Work Positions');
    case 'work-position-details':
      return workPosition && workPosition?.name;
    case 'add-work-position':
      return i18next.t('addworkPosition');
    case 'edit-work-position':
      return workPosition && workPosition.name;
    case 'trainings':
      return i18next.t('Trainings');
    case 'training-details':
      return training && training?.name;
    case 'add-training':
      return i18next.t('addTraining');
    case 'edit-training':
      return training && training?.name;
    case 'projects':
      return i18next.t('Projects');
    case 'add-project':
      return i18next.t('Add Project');
    case 'project-details':
      return project?.name && projectText;
    case 'edit-project':
      return project?.name && projectText;
    case 'questionnaires':
      return i18next.t('questionnaires');
    case 'ideabox':
      return i18next.t('ideabox');
    case 'ideabox-details':
      return i18next.t('ideaboxDetails');
    case 'questionnaire-details':
      return i18next.t('questionnaireDetails');
    case 'evaluations':
      return i18next.t('Evaluation');
    case 'add-evaluation':
      return i18next.t('addEvaluation');
    case 'edit-evaluation':
      return i18next.t('editEvaluation');
    case 'view-evaluation':
      return i18next.t('evaluationDetails');
    case 'assets':
      return i18next.t('Assets');
    case 'add-asset':
      return i18next.t('addAsset');
    case 'edit-asset':
      return i18next.t('editAsset');
    case 'asset-details':
      return asset && asset.name;
    case 'asset-logs':
      return asset && asset.name;
    case 'employees':
      return i18next.t('Employees');
    case 'dashboard':
      return i18next.t('Dashboard');
    case 'employee-profile':
    case 'employee-details':
      return employee?.firstName && employee?.lastName && employee.birthDate
        ? employeeText
        : '';
    case 'interviews':
      return i18next.t('Interviews');
    case 'requests':
      return i18next.t('allRequests');
    case 'benefits':
      return i18next.t('benefitTemplates');
    case 'open-positions':
      return i18next.t('Open Positions');
    case 'reports-analytics':
      return i18next.t('Reports & Analytics');
    case 'add-open-position':
      return i18next.t('addOpenPosition');
    case 'edit-open-position':
      return i18next.t('editOpenPosition');
    case 'open-position-details':
      return i18next.t('openPositionDetails');
    case 'certifications':
      return i18next.t('Certifications');
    case 'add-certification':
      return i18next.t('addCertification');
    case 'edit-certification':
      return i18next.t('editCertification');
    case 'certification-details':
      return certification && certification?.name;
    case 'evaluation-periods':
      return i18next.t('evaluationPeriods');
    case 'add-employee':
      return i18next.t('addEmployee');
    case 'add-user':
      return i18next.t('Add User');
    case 'edit-employee':
      return employee?.firstName && employee?.lastName && employee.birthDate
        ? employeeText
        : '';
    case 'departments':
      return i18next.t('Departments');
    case 'department-details':
      return department && department?.name;
    case 'add-department':
      return i18next.t('addDepartment');
    case 'edit-department':
      return i18next.t('editDepartment');
    case 'tracking':
      return '';
    case 'tracker':
      return '';
    case 'onboardings':
      return i18next.t('Onboardings');
    case 'add-onboarding':
      return i18next.t('addOnboarding');
    case 'edit-onboarding':
      return i18next.t('editOnboarding');
    case 'onboarding-details':
      return '';
    case 'settings':
      return i18next.t('Settings');
    case 'tenants':
      return i18next.t('tenants');
    case 'tenant-accounts':
      return tenant && tenant?.name;
    case 'register-account':
      return i18next.t('registerAccount');
    case 'ip-whitelisting':
      return i18next.t('ipWhitelisting');
    case 'public-super-admins':
      return i18next.t('Users');
    case 'super-admin-profile':
      return i18next.t('My Profile');
    case 'payroll-rules':
      return i18next.t('Payroll Rules');
    case 'payroll-rule-details':
      return i18next.t('payrollRuleDetails');
    case 'payroll-report':
      return i18next.t('payrollReports');
    case 'skillset-matching':
      return i18next.t('skillsetMatching');
    case 'data-entry':
      return i18next.t('dataEntry');
    case 'asset-categories':
      return i18next.t('assetCategories');
    case 'vendor-categories':
      return i18next.t('vendorCategories');
    case 'vendor-category-details':
      return i18next.t('vendorCategoryDetails');
    case 'vendor-questionnaire':
      return i18next.t('vendorQuestionnaire');
    case 'vendor-questionnaire-details':
      return vendorQuestionnaireCategory && vendorQuestionnaireCategory?.name;
    case 'details':
      return `${categoryName}`;
    case 'purchase-orders':
      return i18next.t('purchaseOrders');
    case 'invoicing':
      return i18next.t('invoicing');
    case 'purchase-order-details':
      return i18next.t('purchaseOrderDetails');
    case 'invoice-details':
      return i18next.t('invoiceDetails');
    case 'purchase-requests':
      return i18next.t('purchaseRequest');
    case 'vendor-details':
      return i18next.t('vendorDetails');

    default:
      return i18next.t('Candidates');
  }
};

export const getCandidateStatusIcon = (status: string) => {
  switch (status) {
    case CandidateStatus.NEW:
      return (
        <SpaceIcon>
          <CheckCircleOutlined />
        </SpaceIcon>
      );
    case CandidateStatus.VERIFY:
      return (
        <SpaceIcon>
          <CheckIcon />
        </SpaceIcon>
      );
    case CandidateStatus.HIRED:
      return (
        <SpaceIcon>
          <CheckIcon />
        </SpaceIcon>
      );
    case CandidateStatus.REJECTED:
      return (
        <SpaceIcon>
          <CloseIcon />
        </SpaceIcon>
      );
    default:
      return (
        <SpaceIcon>
          <CheckCircleOutlined />
        </SpaceIcon>
      );
  }
};

export const getStageIcon = (stage: string) => {
  switch (stage) {
    case StageName.New:
      return (
        <SpaceIcon>
          <CheckCircleOutlined />
        </SpaceIcon>
      );
    case StageName.CVReview:
      return (
        <SpaceIcon>
          <EyeOutlined />
        </SpaceIcon>
      );
    case StageName.Interview:
      return (
        <SpaceIcon>
          <ScheduleOutlined />
        </SpaceIcon>
      );
    case StageName.PracticalTask:
      return (
        <SpaceIcon>
          <AppstoreAddOutlined />
        </SpaceIcon>
      );
    case StageName.CultureFitMeeting:
      return (
        <SpaceIcon>
          <TeamOutlined />
        </SpaceIcon>
      );
    case StageName.Offer:
      return (
        <SpaceIcon>
          <CheckIcon />
        </SpaceIcon>
      );
    case StageName.OfferAccepted:
      return (
        <SpaceIcon>
          <CheckIcon />
        </SpaceIcon>
      );
    case StageName.Hired:
      return (
        <SpaceIcon>
          <CheckIcon />
        </SpaceIcon>
      );
    case StageName.Disqualified:
      return (
        <SpaceIcon>
          <CloseIcon />
        </SpaceIcon>
      );
    default:
      return (
        <SpaceIcon>
          <CheckCircleOutlined />
        </SpaceIcon>
      );
  }
};

export const formatDescriptionLabel = (
  path: string,
  candidate: CandidateType | null,
  employeeAssignments: EmployeeAssignmnetsType
) => {
  const candidateText = candidate?.currentJobTitle;

  const employeeWorkPosition = employeeAssignments?.workPosition?.name;

  const employeeHireDate = formatDate(employeeAssignments?.hireDate);

  switch (path) {
    case 'profile':
    case 'edit-profile':
    case 'hire-candidate':
      return candidateText;
    case 'employee-profile':
    case 'edit-employee':
      if (!employeeWorkPosition || !employeeHireDate) return '';
      return (
        <>
          {`${employeeWorkPosition}, ${i18next.t('since')} `}
          <HireDateStyle color="success">{employeeHireDate}</HireDateStyle>
        </>
      );

    default:
      return '';
  }
};

export const pathnamesForStickyHeader = [
  '/candidates/add-candidate',
  '/open-positions/add-open-position',
  '/employees/add-employee',
  '/candidates/edit-profile',
  '/open-positions/edit-open-position',
  '/open-positions/open-position-details',
];

export const candidateRoutesWithStatusBadge = [
  'profile',
  'edit-profile',
  'hire-candidate',
];

export const routesWithSkeleton = [
  'profile',
  'hire-candidate',
  'edit-user-profile',
  'change-password',
  'edit-profile',
  'employee-profile',
  'project-details',
  'questionnaire-details',
  'edit-project',
  'work-position-details',
  'training-details',
  'employee-details',
  'certification-details',
  'department-details',
  'skills',
  'asset-details',
  'open-position-details',
  'asset-logs',
  'edit-asset',
  'category-details',
  'vendor-questionnaire-details',
  'skillset-matching',
];
