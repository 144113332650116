import React from 'react';
import { Col, Row, Tooltip } from 'antd';

import { EmployeeType } from 'types/Employee';
import {
  ItemLabel,
  ItemValue,
} from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import {
  StyledProjectEmployeesRow,
  StyledProjectEmployeeName,
} from './StyledProjectEmployees';

interface ProjectEmployeeInfoProps {
  employee: EmployeeType;
}

const ProjectEmployeeInfo: React.FC<ProjectEmployeeInfoProps> = ({
  employee,
}) => {
  const { t } = useTranslation();
  const onEmployeeNameClick = () => {
    window.open(`/employees/employee-profile?id=${employee?.id}`);
  };

  const employmentType =
    employee?.employeeEmploymentType?.at(0)?.employmentType?.name;

  return (
    <StyledProjectEmployeesRow justify="space-between" gutter={[0, 10]}>
      <Col xs={24} sm={24} md={24} lg={8}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ItemLabel>{t('fullName')}</ItemLabel>
              </Col>
              <Col span={24}>
                <Row gutter={[5, 0]} align="middle">
                  <Col>
                    <StyledProjectEmployeeName
                      ellipsis
                      onClick={onEmployeeNameClick}
                    >
                      {`${employee?.firstName || ''} ${
                        employee?.lastName || ''
                      }`}
                    </StyledProjectEmployeeName>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ItemLabel>{t('phoneNumber')}</ItemLabel>
              </Col>
              <Col span={24}>
                <ItemValue ellipsis>
                  {`${employee?.phoneNumberPrefix || ''}  ${
                    employee?.phoneNumber || ''
                  }`}
                </ItemValue>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ItemLabel>{t('employmentType')}</ItemLabel>
              </Col>
              <Col span={24}>
                <ItemValue ellipsis>{employmentType || '-'}</ItemValue>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ItemLabel>{t('payType')}</ItemLabel>
              </Col>
              <Col span={24}>
                <ItemValue ellipsis>{`${employee?.payType}`}</ItemValue>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ItemLabel>{t('workEmail')}</ItemLabel>
              </Col>
              <Col span={24}>
                <Tooltip title={employee?.workEmail}>
                  <ItemValue ellipsis copyable>
                    {`${employee?.workEmail}`}
                  </ItemValue>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </StyledProjectEmployeesRow>
  );
};

export default ProjectEmployeeInfo;
