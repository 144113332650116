import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import DataGrid from 'components/common/DataGrid/DataGrid';
import { TrackingHoursColumns } from 'table-configuration/trackingHoursConfigs/TrackingHoursConfig';
import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import TrackingCard from 'ui-v2/routes/tracking/trackingCard';
import { useSearchParams } from 'react-router-dom';
import { deleteTrackedHourById } from 'api/trackedHoursService';
import { toast } from 'react-toastify';
import { toastErrorMessages } from 'utils/utilFunctions';
import TrackinHourModalForm from 'components/NewForms/TrackingHourModalForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/reducers';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { useTranslation } from 'react-i18next';

const TrackingDetailed: React.FC = () => {
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const {
    fetch,
    trackedHoursDetails: { data, loading, meta, overallTotal, sectionTotal },
    urlState: { tab },
    TabLabel,
  } = useTrackedHoursData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (tab === TabLabel.detailed) {
      fetch({});
    }
  }, [tab]);

  const onPageChange = useCallback((page: number) => {
    fetch({ pageOptions: { page } });
  }, []);

  const onDelete = (id: string) => {
    deleteTrackedHourById(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Time entry deleted!');
          fetch({ pageOptions: { page: meta?.page || 1, take: 10 } });
        }
      })
      .catch(toastErrorMessages);
  };

  const onSuccess = useCallback(() => {
    if (tab === TabLabel.detailed) {
      fetch({});
      setModalVisible(false);
    }
  }, [tab]);

  const toogleModalVisibility = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  useEffect(() => {
    if (tab === TabLabel.detailed) {
      if (searchParams.get('edit-hour')) {
        toogleModalVisibility();
      }
      if (searchParams.get('delete-hour')) {
        onDelete(searchParams.get('delete-hour') as string);
        searchParams.delete('delete-hour');
      }
      setSearchParams(searchParams);
    }
  }, [searchParams, tab]);

  const canOpen =
    authUserRole &&
    [
      AUTH_ROLES.AMDIN,
      AUTH_ROLES.HR,
      AUTH_ROLES.EMPLOYEE,
      AUTH_ROLES.PAYROLL_MANAGER,
    ].includes(authUserRole);

  return (
    <>
      <Row>
        <Col span={24}>
          <TrackingCard
            overallTitle="Overall approved hours:"
            perPageTitle={t('hoursPerPage')}
            pageTotal={sectionTotal}
            overallTotal={overallTotal}
            loading={loading}
            isEmpty={!data?.length}
            {...(authUserRole !== AUTH_ROLES.GUEST &&
              authUserRole !== AUTH_ROLES.GUEST_TRACKING_HOURS && {
                onAddHourClick: toogleModalVisibility,
                canAddHour: true,
              })}
          >
            {data?.length && (
              <DataGrid
                columns={TrackingHoursColumns}
                data={data}
                loading={loading}
                paginationName="TrackingHours"
                multipleSelect={false}
                totalItems={meta?.itemCount}
                currentPage={meta?.page}
                totalPages={meta?.pageCount}
                onPageChange={onPageChange}
              />
            )}
          </TrackingCard>
        </Col>
      </Row>
      {modalVisible && canOpen && (
        <TrackinHourModalForm
          open={modalVisible}
          onClose={toogleModalVisibility}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default TrackingDetailed;
