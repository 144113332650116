import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import NotesComponent from 'ui-v2/components/Notes';
import EmployeeWorkExperience from 'ui-v2/components/Employees/WorkExperience';
import EmployeeWorkInformation from 'ui-v2/components/Employees/WorkInformation';
import EmployeeUploadDocument from 'ui-v2/components/Employees/UploadDocument';
import EmployeeAssets from 'ui-v2/components/Employees/Assets';
import BankDetails from 'ui-v2/components/Employees/BankDetails';
import EmployeeTimeOff from 'ui-v2/components/Employees/TimeOff';
import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import EmployeeTraining from 'ui-v2/components/Employees/Training';
import EmergencyContact from 'ui-v2/components/EmergyContact';
import EmployeeProjectInformation from 'ui-v2/components/Employees/ProjectInformation';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEmployeeAssignments,
  fetchEmployeesDetail,
} from 'redux/employees/actions';
import RadarSkills from 'ui-v2/components/Employees/RadarSkills';
import EmployeeOnboarding from 'ui-v2/components/Employees/Onboarding';
import { EMPLOYEE_ID } from 'utils/constants';
import { useLocation, useSearchParams } from 'react-router-dom';
import EmployeeBenefits from 'ui-v2/components/Employees/Benefit';
import EmployeeEvaluation from 'ui-v2/components/EmployeeEvaluationDetails';
import useHeader from 'ui-v2/hooks/useHeader';
import SkillList from 'ui-v2/components/Skills';
import BonusComponent from 'ui-v2/components/Bonus';
import BaseTabs from 'ui-v2/components/tabs/BaseTabs';
import BaseTab from 'ui-v2/components/tabs/BaseTab';
import { useTranslation } from 'react-i18next';
import { fetchAllSkills } from 'redux/skills/actions';
import { AUTH_ROLES } from 'types/Auth';
import OffboardingComponent from 'ui-v2/components/OffboadingComponent';
import EmployeeGoals from 'ui-v2/components/Employees/EmployeeGoals';
import EmployeeAttendance from 'ui-v2/components/Employees/EmployeeAttendance/EmployeeAttendance';
import styles from './styles.module.scss';
import EmployeeProfile from './EmployeeDetailsComponents/EmployeeProfile';
import { StyledDiv } from './EmployeeDetailsStyledComponents';

const EmployeeDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams?.get('tab') ?? '1'
  );
  const authUserState = useSelector((state: any) => state.authUser);
  const { employeeId, employeeDetail, loading, employeeAssignments } =
    useEmployeesData();
  const { currentRoute, hideDetails } = useHeader();
  const userRole = authUserState?.authUserRole;
  useEffect(() => {
    dispatch(
      fetchEmployeesDetail(
        (searchParams.get('id') as string) ||
          (localStorage.getItem(EMPLOYEE_ID) as string)
      )
    );
  }, [authUserState?.data?.id]);

  useEffect(() => {
    if (employeeDetail?.id) {
      dispatch(
        fetchEmployeeAssignments(
          (searchParams.get('id') as string) ||
            (localStorage.getItem(EMPLOYEE_ID) as string)
        )
      );
      dispatch(fetchAllSkills());
    }
  }, [employeeDetail]);

  useEffect(() => {
    const el = location.hash;
    if (el) {
      const elementId = el.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setSearchParams(searchParams);
  }, [selectedTab, searchParams, location.hash]);
  return (
    <Content position="stretch">
      <BaseTabs
        defaultActiveKey={selectedTab}
        className={styles['ant-tabs-nav']}
        onTabClick={setSelectedTab}
      >
        <BaseTab tab={t('employeeDetails')} key="1">
          {selectedTab === '1' && (
            <Content position="grid">
              <>
                <>
                  <EmployeeProfile />
                  <EmployeeWorkInformation
                    employeeDetail={employeeDetail}
                    employeeAssignments={employeeAssignments}
                    loading={loading}
                  />
                  <StyledDiv id="projects">
                    <EmployeeProjectInformation
                      assignments={employeeAssignments}
                      loading={loading}
                      visibility={currentRoute !== hideDetails}
                    />
                  </StyledDiv>
                  <EmployeeWorkExperience
                    workExperience={employeeDetail?.experience}
                    loading={loading}
                  />
                  {employeeDetail?.skillSubCategoriesEmployees && (
                    <SkillList isEmployeePortal isDisabled={false} />
                  )}
                </>
                <>
                  {employeeDetail?.skillSubCategoriesEmployees && (
                    <RadarSkills
                      employeeId={employeeDetail?.id}
                      employeeSkills={
                        employeeDetail?.skillSubCategoriesEmployees
                      }
                      loading={loading}
                    />
                  )}
                  {employeeDetail?.emergencyContact && (
                    <EmergencyContact
                      employeeId={employeeDetail?.id}
                      emergencyContact={employeeDetail?.emergencyContact}
                      loading={loading}
                    />
                  )}
                  <EmployeeEvaluation
                    employeeId={employeeDetail?.id}
                    employeeEvaluation={employeeDetail?.evaluations}
                    loading={loading}
                  />
                  {userRole !== AUTH_ROLES.EMPLOYEE && (
                    <NotesComponent
                      employeeId={employeeDetail?.id}
                      notes={employeeDetail?.employeeNotes || []}
                      loading={loading}
                    />
                  )}
                  <EmployeeGoals
                    key="EmployeeGoals"
                    id={
                      (searchParams.get('id') as string) || employeeDetail?.id
                    }
                  />
                  <BonusComponent employeeId={employeeDetail?.id} />
                  {userRole !== AUTH_ROLES.EMPLOYEE && (
                    <OffboardingComponent
                      employeeId={
                        (searchParams.get('id') as string) || employeeDetail?.id
                      }
                      workPositionId={employeeAssignments?.workPosition?.id}
                      locationId={employeeDetail?.tenantLocation?.id}
                    />
                  )}
                </>
              </>
            </Content>
          )}
        </BaseTab>
        <BaseTab tab={t('documents')} key="2">
          {selectedTab === '2' && (
            <Content position="strech">
              <EmployeeUploadDocument
                employeeId={employeeDetail?.id ?? employeeId}
              />
            </Content>
          )}
        </BaseTab>
        <BaseTab tab={t('assets')} key="3">
          {selectedTab === '3' && (
            <EmployeeAssets
              employeeId={employeeDetail?.id ?? employeeId}
              assets={employeeDetail?.employeeAssets}
              visibility={currentRoute !== hideDetails}
            />
          )}
        </BaseTab>
        <BaseTab tab={t('training')} key="4">
          {selectedTab === '4' && (
            <EmployeeTraining
              employeeId={employeeDetail?.id ?? employeeId}
              visiblity={currentRoute !== hideDetails}
            />
          )}
        </BaseTab>
        <BaseTab tab={t('timeOff')} key="5">
          {selectedTab === '5' && (
            <EmployeeTimeOff
              employeeId={employeeDetail?.id ?? employeeId}
              loading={loading}
            />
          )}
        </BaseTab>
        <BaseTab tab={t('onboarding')} key="6">
          {selectedTab === '6' && (
            <EmployeeOnboarding
              employee={employeeDetail}
              visiblity={currentRoute !== hideDetails}
            />
          )}
        </BaseTab>
        <BaseTab tab={t('benefits')} key="7">
          {selectedTab === '7' && (
            <EmployeeBenefits
              employeeId={employeeDetail?.id ?? employeeId}
              visibility={currentRoute !== hideDetails}
            />
          )}
        </BaseTab>
        <BaseTab tab={t('bankDetails')} key="8">
          {selectedTab === '8' && (
            <BankDetails employeeId={employeeDetail?.id ?? employeeId} />
          )}
        </BaseTab>
        <BaseTab tab={t('Employee Attendance')} key="9">
          {selectedTab === '9' && (
            <EmployeeAttendance employeeId={employeeDetail?.id ?? employeeId} />
          )}
        </BaseTab>
      </BaseTabs>
    </Content>
  );
};

export default EmployeeDetails;
