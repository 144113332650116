import { combineReducers } from 'redux';

import { authReducer } from 'redux/authUser/reducer';
import { UserReducer, UsersReducer } from 'redux/users/reducer';
import { ModalReducer } from 'redux/UI/ModalStore/reducer';
import { DataGridReducer } from 'redux/UI/Datagrid/reducer';
import {
  CandidateOffersReducer,
  CandidatesReducer,
  InterviewsReducer,
  OpenPositionStageReducer,
  ScheduleInterviewReducer,
  SelectedOpenPosition,
  SingleCandidatesReducer,
} from 'redux/candidates/reducer';
import {
  DepartmentsReducer,
  singleDepartment,
} from 'redux/departments/reducer';
import { TasksReducer } from 'redux/tasks/reducer';
import {
  WorkPositionsReducer,
  WorkPositionReducer,
} from 'redux/workPositions/reducer';
import { ProjectsReducer, SingleProjectReducer } from 'redux/projects/reducer';
import {
  QuestionnairesReducer,
  SingleQuestionnaireReducer,
} from 'redux/questionnaires/reducer';

import { EmployeesReducer } from 'redux/employees/reducer';
import { AssetsReducer, AssetReducer } from 'redux/assets/reducer';
import { AttendanceReducer } from 'redux/employeeAttendance/reducer';
import { AssetLogsReducer } from 'redux/assetLogs/reducer';
import { OpenPositionsReducer } from 'redux/openPositions/reducer';
import { TrainingsReducer, singleReducer } from 'redux/trainings/reducer';
import {
  CertificationsReducer,
  singleCertification,
} from 'redux/certifications/reducer';
import { InterviewsPageReducer } from 'redux/interviewsPage/reducer';
import {
  EvaluationPeriodsReducer,
  singleEvaluationPeriod,
} from 'redux/evaluationPeriods/reducer';
import { TableConfigReducer } from 'redux/tableConfig/reducer';
import {
  OnboardingsReducer,
  SingleOnboardingReducer,
} from 'redux/onboardings/reducer';
import { TenantsReducer } from 'redux/tenants/reducer';
import { IpAccessReducer } from 'redux/ipAccess/reducer';
import { TenantConfigReducer } from 'redux/tenantConfig/reducer';
import { RequestReducer } from 'redux/requests/reducer';
import { PublicSuperAdminsReducer } from 'redux/publicSuperAdmins/reducer';
import { hrDutiesReducer, isClosedBannerReducer } from 'redux/hrDuties/reducer';
import { SkillsReducer } from 'redux/skills/reducer';
import {
  PayrollRuleReducer,
  SinglePayrollRuleReducer,
} from 'redux/payrollRules/reducer';
import {
  PayrollDatesReducer,
  SingleEmployeePayrollReportReducer,
} from 'redux/payrollReport/reducer';
import { TrackedHoursReducer } from 'redux/trackings/reducer';
import { NotificationsReducer } from 'redux/notifications/reducer';
import { EmployeesReviewsReducer } from 'redux/employeeReviews/reducer';
import { AssetCategoriesReducer } from 'redux/assetCategories/reducer';
import {
  PurchaseRequestReducer,
  PurchaseRequestsReducer,
} from 'redux/purchaseRequest/reducer';
import { VendorsReducer } from 'redux/vendor/reducer';
import { VendorCategoryReducer } from 'redux/vendorCategories/reducer';
import { PurchaseOrderReducer } from 'redux/purchaseOrders/reducer';
import { InvoicingReducer } from 'redux/invoicing/reducer';
import { VendorQuestionnaireReducer } from 'redux/vendorQuestionnaire/reducer';
import { OffboardingReducer } from 'redux/offboarding/reducer';
import { KnowledgeReducer } from 'redux/knowledge/reducer';
import { IdeaboxReducer, SingleIdeaboxReducer } from 'redux/ideabox/reducer';
import { GoalReducer } from 'redux/goal/reducer';
import { SkillsetMatchingReducer } from 'redux/skillsetMatching/reducers';
import { HeadcountReducer } from 'redux/headcount/reducer';
import { FeedReducer } from 'redux/feed/reducer';
import { EmployeesSkimReducer } from '../employeeSkim/reducer';

const rootReducer = combineReducers({
  vendorQuestionnaire: VendorQuestionnaireReducer,
  authUser: authReducer,
  users: UsersReducer,
  user: UserReducer,
  modal: ModalReducer,
  dataGrid: DataGridReducer,
  candidateOffers: CandidateOffersReducer,
  singleCandidate: SingleCandidatesReducer,
  candidates: CandidatesReducer,
  scheduleInterview: ScheduleInterviewReducer,
  departments: DepartmentsReducer,
  singleDepartment,
  tasks: TasksReducer,
  skills: SkillsReducer,
  workPositions: WorkPositionsReducer,
  openPositions: OpenPositionsReducer,
  projects: ProjectsReducer,
  singleProject: SingleProjectReducer,
  questionnaires: QuestionnairesReducer,
  singleQuestionnaire: SingleQuestionnaireReducer,
  ideabox: IdeaboxReducer,
  singleIdeabox: SingleIdeaboxReducer,
  employees: EmployeesReducer,
  interviews: InterviewsReducer,
  assets: AssetsReducer,
  asset: AssetReducer,
  attendance: AttendanceReducer,
  assetLogsReducer: AssetLogsReducer,
  skillsetMatchingReducer: SkillsetMatchingReducer,
  trainings: TrainingsReducer,
  vendors: VendorsReducer,
  certifications: CertificationsReducer,
  interviewsPage: InterviewsPageReducer,
  evaluationPeriods: EvaluationPeriodsReducer,
  singleEvaluationPeriod,
  tableConfigReducer: TableConfigReducer,
  candidateOpenPositionStage: OpenPositionStageReducer,
  onBoardings: OnboardingsReducer,
  singleOnboarding: SingleOnboardingReducer,
  selectedCandidateOpenPosition: SelectedOpenPosition,
  tenants: TenantsReducer,
  ipWhitelisting: IpAccessReducer,
  singleCertification,
  requestReducer: RequestReducer,
  employeesSkim: EmployeesSkimReducer,
  singleWorkPosition: WorkPositionReducer,
  tenantConfig: TenantConfigReducer,
  singleReducer,
  hrDuties: hrDutiesReducer,
  isClosedBanner: isClosedBannerReducer,

  publicSuperAdmins: PublicSuperAdminsReducer,

  trackingHours: TrackedHoursReducer,
  payrollRules: PayrollRuleReducer,
  payrollRule: SinglePayrollRuleReducer,
  payrollDates: PayrollDatesReducer,
  payrollReportSingleEmployee: SingleEmployeePayrollReportReducer,
  trackedHours: TrackedHoursReducer,
  notifications: NotificationsReducer,
  employeeReviews: EmployeesReviewsReducer,
  knowledge: KnowledgeReducer,
  feed: FeedReducer,
  goal: GoalReducer,
  assetCategories: AssetCategoriesReducer,
  purchaseRequests: PurchaseRequestsReducer,
  purchaseRequest: PurchaseRequestReducer,
  vendorCategories: VendorCategoryReducer,
  purchaseOrders: PurchaseOrderReducer,
  invoicing: InvoicingReducer,
  offboarding: OffboardingReducer,
  headcount: HeadcountReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
