import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

// import Content from 'ui-v2/components/Content';
import BubbleChart from './BubbleChart';
import PieChart from './PieChart';
import PieChartAsset from './PieChartAsset';
import LineChart from './LineChart';
import BarChart from './BarChart';
import Stats from './Stats';
import HeatMapComponent from './Heatmap';
import HeadcountReport from './HeadcountTable';

const DashboardPage = () => (
  // <Content position="stretch">
  <Row gutter={[0, 25]}>
    <Col span={24}>
      <Stats />
    </Col>

    {/* Row for BubbleChart and PieChart */}
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col xxl={16} md={16}>
          <BubbleChart />
        </Col>
        <Col xxl={8} md={8}>
          <PieChart />
        </Col>
      </Row>
    </Col>

    {/* Row for HeatMapComponent and PieChartAsset */}
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col xxl={16} md={16}>
          <HeatMapComponent />
        </Col>
        <Col xxl={8} md={8}>
          <PieChartAsset />
        </Col>
      </Row>
    </Col>

    {/* Row for BarChart and LineChart */}
    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col md={24} xxl={24}>
          <BarChart />
        </Col>
      </Row>
    </Col>

    <Col span={24}>
      <Row gutter={[24, 24]}>
        <Col xxl={14} md={14}>
          <LineChart />
        </Col>
        <Col xxl={10} md={10}>
          <HeadcountReport />
        </Col>
      </Row>
    </Col>
  </Row>
  // </Content>
);

export default DashboardPage;
