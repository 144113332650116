import React from 'react';
import CandidateFilter from '../Filters/CandidatesFilter';
import EmployeesFilter from '../Filters/EmployeesFilter';
import OpenPositionFilter from '../Filters/OpenPositionFilter';
import TenantsFilter from '../Filters/TenantsFilter';
import InterviewsFilter from '../Filters/InterviewsFilter';
import OnboardingsFilter from '../Filters/OnboardingsFilter';
import WorkPositonFilter from '../Filters/WorkPostionFilter';
import EvaluationFilter from '../Filters/EvaluationFilter';
import AssetFilter from '../Filters/AssetFilter';
import ProjectFilter from '../Filters/ProjectFilter';
import CertificationsFilter from '../Filters/CertificationFilter';
import TrainingsFilter from '../Filters/TrainingFilter';
import UsersFilter from '../Filters/UsersFilter';
import TenantAccountsFilter from '../Filters/TenantAccountsFilter';
import PayrollReportsFilter from '../Filters/PayrollReportsFilter';
import PayrollRulesFilter from '../Filters/PayrollRulesFilter';
import SkillsetMatchingFilter from '../Filters/SkillsetMatchingFilter';

export const getFilter: any = (entity: string) => {
  switch (entity) {
    case 'candidates':
      return <CandidateFilter />;
    case 'employees':
      return <EmployeesFilter />;
    case 'interviews':
      return <InterviewsFilter />;
    case 'open-positions':
      return <OpenPositionFilter />;
    case 'evaluations':
      return <EvaluationFilter />;
    case 'certifications':
      return <CertificationsFilter />;
    case 'trainings':
      return <TrainingsFilter />;
    case 'departments':
      return null;
    case 'projects':
      return <ProjectFilter />;
    case 'onboardings':
      return <OnboardingsFilter />;
    case 'work-positions':
      return <WorkPositonFilter />;
    case 'assets':
      return <AssetFilter />;
    case 'tenants':
      return <TenantsFilter />;
    case 'users':
      return <UsersFilter />;
    case 'tenant-accounts':
      return <TenantAccountsFilter />;
    case 'payroll-rules':
      return <PayrollRulesFilter />;
    case 'payroll-report':
      return <PayrollReportsFilter />;
    case 'skillset-matching':
      return <SkillsetMatchingFilter />;
    default:
      return [];
  }
};
